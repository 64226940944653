import type { Link } from '@backmarket/http-api'

import { createHttpEndpoint } from '../../utils'

/**
 * Popular Searches
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/search-api/definition#tag/search/operation/get-search-v2-popular}
 */
export const getPopular = createHttpEndpoint<Array<SearchPopularHit>>({
  method: 'GET',
  operationId: 'searchPopular',
  path: '/bm/search/v3/popular',
})

export type SearchPopularHit = {
  landingId: string
  link: Link
  title: string
  highlightedTitle?: string
}
